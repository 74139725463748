import React, { FC } from "react"
import FinixPaymentForm, {
  FinixOnSuccess,
} from "features/finix/FinixPaymentForm"
import Title from "components/text/Title"
import Button from "components/buttons/Button"
import { Link, useNavigate } from "react-router-dom"
import { useUpdatePaymentMutation } from "app/services"

interface Props {
  isNew: boolean
}

const PaymentMethodEdit: FC<Props> = ({ isNew }) => {
  const [updatePayment, { isLoading }] = useUpdatePaymentMutation()
  const navigate = useNavigate()

  const handleSuccess: FinixOnSuccess = async ({
    paymentInstrument,
    fraudSessionId,
    idempotencyId,
  }) => {
    const updated = await updatePayment({
      paymentInfo: {
        paymentInstrumentId: paymentInstrument.id,
        fraudSessionId: fraudSessionId,
        idempotencyId: idempotencyId,
      },
      transactionCorrelationId: null,
    })
    if (updated) {
      navigate("/account/payment-methods")
    }
  }

  return (
    <div className="pt-8">
      <Title className="mb-8">
        {isNew ? "Card details" : "Edit card details"}
      </Title>
      <FinixPaymentForm
        onSuccess={handleSuccess}
        buttonLabel="Save changes"
        isDisabled={isLoading}
      />
      <div className="mt-5">
        <Link to="/account/payment-methods">
          <Button size="s" color="white" disabled={isLoading}>
            Cancel
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default PaymentMethodEdit
