import { FormFieldI } from "types/core/formTypes"
import { FormFieldType } from "constants/core/formConstants"
import * as yup from "yup"
import { buildYupSchema } from "helpers/core/formHelpers"
import { UseFormSetValue } from "react-hook-form/dist/types/form"
import { stateOptions } from "constants/core/stateConstants"

export const getShippingInfoFields = (
  setValue?: UseFormSetValue<any>,
): FormFieldI[] => [
  {
    type: FormFieldType.TEXT,
    name: "firstName",
    label: "First name",
    placeholder: "First name",
    rules: yup.string().required(),
    joinNext: true,
  },
  {
    type: FormFieldType.TEXT,
    name: "lastName",
    label: "Last name",
    placeholder: "Last name",
    rules: yup.string().required(),
  },
  {
    type: FormFieldType.SELECT,
    name: "country",
    label: "Country",
    placeholder: "Country",
    rules: yup.string().required(),
    options: [
      {
        value: "US",
        label: "United States of America",
      },
    ],
  },
  {
    type: FormFieldType.GOOGLE_PLACES,
    rules: yup.string().required(),
    name: "streetAddress",
    label: "Street address",
    placeholder: "Street address",
    onChange: (place: google.maps.places.PlaceResult) => {
      if (!setValue) {
        return
      }

      if (!place.address_components) {
        return
      }

      // Extract address components
      const addressComponents = place.address_components

      // Helper function to get a specific component by type
      const getAddressComponent = (type: string, long: boolean = true) => {
        let component = addressComponents.find((component) =>
          component.types.includes(type),
        )

        if (long) {
          return component?.long_name || ""
        } else {
          return component?.short_name || ""
        }
      }

      // Populate city, state, zip_code, and country
      const city =
        getAddressComponent("locality") ||
        getAddressComponent("administrative_area_level_2")
      const state = getAddressComponent("administrative_area_level_1", false)
      const zipCode = getAddressComponent("postal_code")
      const country = getAddressComponent("country", false)

      setValue("city", city)
      setValue("state", state)
      setValue("zipCode", zipCode)
      setValue("country", country)

      // Format street address
      const streetNumber = getAddressComponent("street_number")
      const route = getAddressComponent("route")
      const formattedStreetAddress = `${streetNumber} ${route}`.trim()

      setValue("streetAddress", formattedStreetAddress)
    },
  },
  {
    type: FormFieldType.TEXT,
    name: "apt",
    label: "Apt. / Suite No.",
    placeholder: "Apt. / Suite No.",
    rules: yup.string().notRequired(),
  },
  {
    type: FormFieldType.TEXT,
    name: "city",
    label: "City",
    placeholder: "City",
    rules: yup.string().required(),
  },
  {
    type: FormFieldType.SELECT,
    name: "state",
    label: "State",
    placeholder: "State",
    rules: yup.string().required(),
    options: stateOptions,
    joinNext: true,
  },
  {
    type: FormFieldType.TEXT,
    name: "zipCode",
    label: "Postal code",
    placeholder: "Postal code",
    rules: yup
      .string()
      .required("Postal code is required")
      .matches(
        /^\d{5}(-\d{4})?$/,
        "Postal code must be in the format 12345 or 12345-6789",
      ),
  },
]

export const shippingInfoYupSchema = buildYupSchema(getShippingInfoFields())
